// Menu principal
function menuMain() {
    var state = '';

    // set the type of device: Desktop or Smartphone
    state = getState();

    // initialize the behavior of the menu if window resize
    $(window).on('resize', function() {
        state = getState();
        // recalc height container submenu level-2
        recalcHeightSubmenu(state,  $('.menu-main ul.menu-main__level-1 > li.active ul.menu-main__subul-level-2 > li.active > .menu-main__sublink-icon'));
    });

    // initialize the behavior of the menu
    init();

    // close menu when click in non-menu areas
    $(document).on('click', close);

    // initialize the behavior of the menu
    function init() {

        // toggle state submenu level-1
        $('.menu-main ul li > [class^="menu-main__sublink-level-1"]').on('click', function (e) {
            state = getState();

            if(state === 'mediaDesktop' && $(this).parent().children('[class^="menu-main__level-"]').length > 0) {
                e.preventDefault();
                if ($(this).parent().hasClass('active')) {
                    $(this).parent().removeClass('active');
                    $(this).nextAll('[class^="menu-main__level-"]').removeClass('show');
                }
                else {
                    $(this).parent().siblings().removeClass('active');
                    $(this).parent().siblings().children('[class^="menu-main__level-"]').removeClass('show');
                    $(this).parent().addClass('active');
                    $(this).nextAll('[class^="menu-main__level-"]').addClass('show');
                    if($('.menu-main__subul-level-2 > li').hasClass('active')) {
                        // recalc height container submenu level-2
                        recalcHeightSubmenu(state, $(this).next().find('> li.active > .menu-main__sublink-icon'));
                    }
                }
            } else {
                return true;
            }
        });

        // mobile button toggle state submenu level-1
        $('.menu-main ul.menu-main__level-1 > li > .menu-main__sublink-icon').on('click', function (e) {
            state = getState();
            e.preventDefault();

            if ($(this).parent().hasClass('active')) {
                $(this).parent().removeClass('active');
                $(this).nextAll('[class^="menu-main__level-"]').removeClass('show');
            }
            else {
                $(this).parent().siblings().removeClass('active');
                $(this).parent().siblings().children('[class^="menu-main__level-"]').removeClass('show');
                $(this).parent().addClass('active');
                $(this).nextAll('[class^="menu-main__level-"]').addClass('show');
            }
        });

        // toggle state submenu level-2
        $('.menu-main ul.menu-main__subul-level-2 li > .menu-main__sublink-icon').on('click', function (e) {
            state = getState();
            e.preventDefault();

            if ($(this).parent().hasClass('active')) {
                $(this).parent().removeClass('active');
                $(this).nextAll('[class^="menu-main__level-"]').removeClass('show');
                $(this).parents('.menu-main__level-2').removeClass('active');
            } else {
                // recalc height container submenu level-2
                recalcHeightSubmenu(state,  $(this));

                $(this).parent().siblings().removeClass('active');
                $(this).parent().siblings().children('[class^="menu-main__level-"]').removeClass('show');
                $(this).parent().addClass('active');
                $(this).nextAll('[class^="menu-main__level-"]').addClass('show');
                $(this).parents('.menu-main__level-2').addClass('active');
            }
        });

        // close all submenu .menu-main__level-...
        $('.menu-main__closer').on('click', function () {
            $('.menu-main ul li.active').children('[class^="menu-main__sublink-level-"]').next().removeClass('show');
            $('.menu-main ul li.active').removeClass('active');
            $('.menu-main__level-2').height('');
            $('.menu-main__level-3').height('');
        });

        // close submenu .menu-main__subul-level-2 when click on button .menu-main__level-3-back on desktop device
        $('.menu-main__level-3-back').on('click', function (e) {
            $(this).parents('.menu-main__level-3').removeClass('show').removeAttr('style').parent().removeClass('active');
            $(this).parents('.menu-main__level-2').removeClass('active').removeAttr('style');
        });

        // open mobile menu when click on burger menu button
        $('.show-btn').on('click', function () {
            $(this).closest('.mobile-component').addClass('show');
            $('#page').addClass('menu-show');
            $('#menu-main').addClass('show');
        });

        // close mobile menu
        $('.close-btn').on('click', function () {
            $(this).closest('.mobile-component').removeClass('show');
            $('#page').removeClass('menu-show');
            $('#menu-main').removeClass('show');
        });
    }

    // recalc height container submenu level-2
    function recalcHeightSubmenu (state, elemButtonToggle) {
        var wrapHeight = elemButtonToggle.parent().find('.menu-main__level-3').height();
        var mainHeight = elemButtonToggle.parents('.menu-main__level-2').height();
        if(state == 'mediaDesktop') {
            if (mainHeight <= wrapHeight) {
                elemButtonToggle.parents('.menu-main__level-2').height(wrapHeight + 45 + 'px');
            }
            if (mainHeight > wrapHeight) {
                elemButtonToggle.parent().find('.menu-main__level-3').height(mainHeight - 45 + 'px');
            }
        } else if(state == 'mediaSmartphone') {
            elemButtonToggle.parents('.menu-main__level-2').height('');
            elemButtonToggle.parent().find('.menu-main__level-3').height('');
        }
    }

    // close menu when click in non-menu areas
    function close(e) {
        if (state !== 'mediaSmartphone' && !$(e.target).closest('.menu-main__level-1').length) {
            $('.menu-main__level-1').find('.active, .show').removeClass('active show');
        }
    }

    // set the type of device: Desktop or Smartphone
    function getState() {
        var m = 'mediaDesktop';

        if ($(window).width() < 1280) {
            m = 'mediaSmartphone';
        }

        return m;
    }



    /* function init(state) {
        changeState(state);
        $(window).on('resize', resizeHandler);
        $(document).on('click', close);
    }

    function changeState(state) {
        if (state == 'mediaDesktop') {
            initDesktop();
        }
        else if (state == 'mediaSmartphone') {
            initSmartphone();
        }
    }

    function initSmartphone() {

        $('.menu-main ul li > .menu-main__sublink-icon').on('click', function (e) {
            e.preventDefault();
            // e.stopPropagation();
            if ($(this).parent().hasClass('active')) {
                $(this).parent().removeClass('active');
                $(this).nextAll('[class^=menu-main__level-]').removeClass('show');
            }
            else {
                $(this).parent().siblings().removeClass('active');
                $(this).parent().siblings().children('[class^=menu-main__level-]').removeClass('show');
                $(this).parent().addClass('active');
                $(this).nextAll('[class^=menu-main__level]').addClass('show');
            }
        });

        $('.menu-main ul li > [class^="menu-main__sublink-level-1"]').on('click', function (e) {
            // e.preventDefault();
            e.stopPropagation();
            // if($(this).parent().hasClass('active')) {
            //   $(this).parent().removeClass('active');
            //   $(this).nextAll('[class^=menu-main__level-]').removeClass('show');
            // }
            // else {
            //   $(this).parent().siblings().removeClass('active');
            //   $(this).parent().siblings().children('[class^=menu-main__level-]').removeClass('show');
            //   $(this).parent().addClass('active');
            //   $(this).nextAll('[class^=menu-main__level]').addClass('show');
            // }
        });

        $('.menu-main ul li > [class^="menu-main__sublink-level-"]')
            .not('.menu-main__sublink-level-1')
            .on('click', function (e) {
                // e.preventDefault();
                // e.stopPropagation();
                if ($(this).parent().parent().hasClass('active')) {
                    $(this).parent().parent().removeClass('active');
                    $(this).parent().nextAll('[class^="menu-main__level-"]').removeClass('show');
                }
                else {
                    $(this).parent().parent().siblings().removeClass('active');
                    $(this).parent().parent().siblings().children('[class^="menu-main__sublink-level-"]').nextAll('[class^=menu-main__level-]').removeClass('show');
                    $(this).parent().parent().addClass('active');
                    $(this).parent().nextAll('[class^=menu-main__level-]').addClass('show');
                }
            });

        $('.show-btn').on('click', function () {
            $(this).closest('.mobile-component').addClass('show');
            $('#page').addClass('menu-show');
            $('#menu-main').addClass('show');
        });

        $('.close-btn').on('click', function () {
            $(this).closest('.mobile-component').removeClass('show');
            $('#page').removeClass('menu-show');
            $('#menu-main').removeClass('show');
        });
    }

    function initDesktop() {

        $('.menu-main ul li > [class^="menu-main__sublink-level-1"]').on('click', function (e) {
            e.preventDefault();
            // e.stopPropagation();
            if ($(this).parent().hasClass('active')) {
                $(this).parent().removeClass('active');
                $(this).nextAll('[class^="menu-main__level-"]').removeClass('show');
            }
            else {
                $(this).parent().siblings().removeClass('active');
                $(this).parent().siblings().children('[class^="menu-main__level-"]').removeClass('show active');
                $(this).parent().addClass('active');
                $(this).nextAll('[class^="menu-main__level-"]').addClass('show');
            }
        });

        $('.menu-main ul li > .menu-main__sublink-icon').on('click', function (e) {
            e.preventDefault();
            // e.stopPropagation();
            console.log($(this));
            if ($(this).parent().hasClass('active')) {
                $(this).parent().removeClass('active');
                $(this).nextAll('[class^="menu-main__level-"]').removeClass('show');
                $(this).parents('.menu-main__level-2').removeClass('active');
            }
            else {
                var wrapHeight = $(this).parent().find('.menu-main__level-3').height();
                var mainHeight = $(this).parents('.menu-main__level-2').height();
                if (mainHeight <= wrapHeight) {
                    $(this).parents('.menu-main__level-2').height(wrapHeight + 45 + 'px');
                }
                if (mainHeight > wrapHeight) {
                    $(this).parent().find('.menu-main__level-3').height(mainHeight - 45 + 'px');
                }
                $(this).parent().siblings().removeClass('active');
                $(this).parent().siblings().children('[class^="menu-main__sublink-level-"]').next().removeClass('show');
                $(this).parent().addClass('active');
                $(this).nextAll('[class^="menu-main__level-"]').addClass('show');
                $(this).parents('.menu-main__level-2').addClass('active');
            }
        });

        $('.menu-main__closer').on('click', function (e) {
            e.preventDefault();
            //'[class ^= pre]'
            $('.menu-main ul li.active').children('[class^="menu-main__sublink-level-"]').next().removeClass('show');
            $('.menu-main ul li.active').removeClass('active');
        });

        $('.menu-main__level-3-back').on('click', function (e) {
            e.preventDefault();

            var $parent = $(this).parents('.menu-main__level-3');

            $parent
                .removeClass('show')
                .removeAttr('style')
                .parent()
                .removeClass('active');

            $(this).parents('.menu-main__level-2').removeClass('active').removeAttr('style');
        });
    }

        // function resizeHandler() {
    //     var s = getState();
    //
    //     if (state !== s) {
    //         state = s;
    //         changeState(state);
    //     }
    // }

    */
}






